<template>
  <section id="Calculator" :class="background">
    <Wrap class="lg">
      <Headline>{{ headline || $t('headline') }}</Headline>
      <div class="info">
        <h2 class="title">
          {{ $t('title') }}
        </h2>
        <small>
          {{ $t('instruction') }}
        </small>
      </div>
      <div class="slider-container">
        <Slider
          class="slider"
          :format="tooltipFormat"
          :max="maxValue"
          :min="minValue"
          :processStyle="{
            backgroundColor: '#4dbeed',
          }"
          :tooltipPosition="tooltipPosition"
          :tooltipStyle="{
            background: '#4dbeed',
            borderColor: '#4dbeed',
            cursor: 'pointer',
            padding: '0.6rem 0.5rem 0.5rem',
            textAlign: 'center',
            whiteSpace: 'pre',
          }"
          :value.sync="value" />
      </div>

      <SimpleTable :cols="[8, 4]" :rows="calculated" />

      <div class="cta-block">
        <Btn :to="{ name: $route.name, hash: '#FooterContact' }">
          {{ $t('cta') }}
        </Btn>
      </div>

      <div class="disclaimer">
        <p v-for="(disclaimer, index) in $ta('disclaimers')" :key="index">
          {{ disclaimer.replace('{priceKwhCents}', priceKwhCents) }}
        </p>
        <!--
        <p>
          ** Vidutiniai taupymai paskaičiuoti remiantis dabartine elektros kaina ({{ Math.round(project.priceKwh * 100) }}ct kWh).
        </p>
        <p>
          *** Elektrinė bus įrengta naudojant patikimiausių pasaulinių kompanijų įrangą. Įrenginio dalies modulių efektyvumo garantija 80 proc. 25 m.
        </p>
        -->
      </div>
    </Wrap>
  </section>
</template>

<script>
import SimpleTable from './SimpleTable'
import Slider from './Slider'
import { getters } from '../utils'

export default {
  components: {
    SimpleTable,
    Slider,
  },
  props: {
    background: String,
    headline: String,
    project: {
      type: Object,
      default() {
        return {
          capacity: null,
          feeMonthly: 2.64,
          lifetimeYears: 25,
          omAnnual: 0,
          price: {
            high: 1099.89,
            middle: 911.5051,
            low: 969.21,
          },
          capacityBreakpoints: [5.28, 7.13, 9.9],
          // priceHigh: 1099.89,
          // priceMiddle: 1036.97,
          // priceLow: 969.21,
          priceKwh: 0.141,
          subsidy: 323,
          maxSubsidableCapacity: 10,
          vatRate: 1.21,
          yieldAnnual: 1050,
          loadAdministrativeFee: 0.019, // 1.9%
          interestRate: 0.046, // 4.6%
          loanDuration: 10,
        }
      },
    },
  },
  data() {
    return {
      value: 180,
      minValue: 1,
      maxValue: 1010,
      // inflation: 1.02,
      // inflationElectricityRemaining: 1.03,
      // inflationElectricityInitial: 1.05,
      inflation: 1,
      inflationElectricityRemaining: 1,
      inflationElectricityInitial: 1,
      tooltipFormat: v => `${v} kWh/${this.$t('month')} \n ${Math.round(v * this.project.priceKwh)} EUR/${this.$t('month')}`,
    }
  },
  computed: {
    price() {
      if (this.pvCapacityPerPerson < this.project.capacityBreakpoints[0]) { return this.project.price.high }
      if (this.pvCapacityPerPerson < this.project.capacityBreakpoints[1]) {
        const pvPriceBetweenSteps = (this.pvCapacityPerPerson - this.project.capacityBreakpoints[0]) * 10 * -8.41567567568 // 3.1515 = D31
        const pvPriceBetweenStepsWithVat = pvPriceBetweenSteps * this.project.vatRate
        return pvPriceBetweenStepsWithVat + this.project.price.high
      }
      if (this.pvCapacityPerPerson < this.project.capacityBreakpoints[2]) {
        const pvPriceBetweenSteps = (this.pvCapacityPerPerson - this.project.capacityBreakpoints[1]) * 10 * 1.7216606498 // 1.8855 = E31
        const pvPriceBetweenStepsWithVat = (pvPriceBetweenSteps * this.project.vatRate)
        return pvPriceBetweenStepsWithVat + this.project.price.middle
      }

      return this.project.price.low
    },
    tooltipPosition() {
      if (this.screenSizes.includes('xl')) { return 'top' }

      const breakPercentage = 0.05
      const valuePercentage = this.value / this.maxValue

      if (valuePercentage < breakPercentage) { return 'right' }
      if (valuePercentage > 1 - breakPercentage) { return 'left' }

      return 'top'
    },
    averageAnnualConsumption() {
      return this.value * 12
    },
    inflationsArray() {
      let previousValue
      return Array.from({ length: 25 }, (v, index) => {
        if (!previousValue) {
          previousValue = 1
          return 1
        }
        const currentValue = this.inflation * previousValue
        previousValue = currentValue
        return currentValue
      })
    },
    inflationsElecArray() {
      let previousValue
      return Array.from({ length: 25 }, (v, index) => {
        if (!previousValue) {
          previousValue = 1
          return 1
        }
        if (index < 6) {
          const currentValue = this.inflationElectricityInitial * previousValue
          previousValue = currentValue
          return currentValue
        }
        const currentValue = this.inflationElectricityRemaining * previousValue
        previousValue = currentValue
        return currentValue
      })
    },
    electricityPriceForCustomerArray() {
      return this.inflationsElecArray.map(x => x * this.project.priceKwh)
    },
    totalSavingsArray() {
      return this.electricityPriceForCustomerArray.map(x => x * this.yearlyPower)
    },
    totalCostsArray() {
      return this.inflationsArray.map(x => x * this.pvCapacityPerPerson * this.project.feeMonthly * 12)
    },
    netSavingsArray() {
      return this.totalSavingsArray.map((x, index) => x - this.totalCostsArray[index])
    },
    monthlyFeeOnInstalledPower() {
      return this.project.feeMonthly * this.pvCapacityPerPerson
    },
    loadAdministrativeFeeOnInstalledPower() {
      return this.project.loadAdministrativeFee * this.stationCostSubsidized
    },
    monthlyLoanRepayment() { // PMT
      const index = this.project.interestRate / 12
      const n = this.project.loanDuration * 12
      const p = this.stationCostSubsidized
      return index * p * Math.pow((1 + index), n) / (1 - Math.pow((1 + index), n)) * -1
    },
    totalNetSavings() {
      return this.totalSavingsArray.reduce((a, b) => a + b, 0) // - this.project.lifetimeYears * this.monthlyFeeOnInstalledPower * 12 - this.monthlyLoanRepayment * 12 * 10 - this.loadAdministrativeFeeOnInstalledPower
    },
    totalFeeOnInstalledPower() {
      return this.project.lifetimeYears * this.monthlyFeeOnInstalledPower * 12
    },
    totalFeeLoan() {
      return this.monthlyLoanRepayment * 12 * this.project.loanDuration + this.loadAdministrativeFeeOnInstalledPower
    },
    totalNetSavingsAfterFees() {
      // return this.totalNetSavings - (this.project.lifetimeYears * this.monthlyFeeOnInstalledPower * 12 - this.monthlyLoanRepayment * 12 * this.project.loanDuration + this.loadAdministrativeFeeOnInstalledPower)
      return this.totalNetSavings - (this.totalFeeOnInstalledPower) - this.stationCostSubsidized
    },
    netSavingsAverage() {
      return (this.totalNetSavings - this.totalFeeOnInstalledPower) / this.project.lifetimeYears
    },
    pvCapacityPerPerson() {
      return (this.averageAnnualConsumption / this.project.yieldAnnual)
    },
    stationCost() {
      return this.pvCapacityPerPerson * this.price
    },
    subsidyTotal() {
      return Math.min(this.pvCapacityPerPerson, this.project.maxSubsidableCapacity) * this.project.subsidy
    },
    stationCostSubsidized() {
      // const subsidyTotal = Math.min(this.pvCapacityPerPerson, this.project.maxSubsidableCapacity) * this.project.subsidy
      return this.stationCost - this.subsidyTotal
    },
    paybackYears() {
      let sumRemaining = this.stationCostSubsidized
      let years = 0
      // eslint-disable-next-line fp/no-loops
      while (sumRemaining > 0) {
        sumRemaining -= this.netSavingsArray[years]
        years++
      }

      if (sumRemaining === 0) { return years }

      const lastMonthFraction = sumRemaining / this.netSavingsArray[years - 1]

      return years + lastMonthFraction
    },
    yearlyPower() {
      return this.averageAnnualConsumption
    },
    calculated() {
      return [
        [
          'Rekomenduojama saulės elektrinės galia, kW',
          this.$options.filters.commaDecimal(this.pvCapacityPerPerson),
        ],
        // [
        //   'Metinė elektros energijos gamyba, kWh/metus',
        //   this.yearlyPower.toFixed(),
        // ],
        [
          'Įsigijimo kaina su parama*, €',
          this.stationCostSubsidized.toFixed(),
        ],
        [
          'Investicijos atsipirkimo laikas, metai',
          // this.paybackYears,
          this.$options.filters.commaDecimal(this.paybackYears, 1),
        ],
        [
          'Preliminari įsigijimo išsimokėtinai įmoka**, €/mėnesį',
          this.monthlyLoanRepayment.toFixed(),
        ],
        [
          'Vidutinis metinis sutaupymas***, €/metus',
          this.netSavingsAverage.toFixed(),
        ],
        [
          'Sutaupymas per garantuotą elektrinės veikimo laikotarpį, €',
          this.totalNetSavingsAfterFees.toFixed(),
        ],
      ]
    },
    priceKwhCents() {
      return (this.project.priceKwh * 100).toFixed(1)
    },
    ...getters('screenSizes'),
  },
}
</script>

<style lang="scss">
#Calculator {
  .slider-container {
    padding: 1rem 1.5rem;
  }

  h2 {
    font-size: $h3;
    margin-bottom: 0.5rem;
    @include lg {
      font-size: $h2;
      margin-bottom: 1rem;
    }
  }

  // .calculator-invalid-capacity {
  //   span {
  //     color: $link-color;
  //     cursor: pointer;
  //     text-decoration: underline;
  //   }
  // }

  .info {
    margin: 3rem 0 1rem;
    text-align: center;
    @include md {
      margin-bottom: 1rem;
    }

    .title {
      display: inline;
      font-size: $h4;
      @include md {
        font-size: $h35;
      }
    }

    > small {
      display: block;
      font-size: $h5;
      margin: 1rem 0 5rem;
      @include md {
        margin: 4rem 0 6rem;
      }
    }
  }

  .cta-block {
    margin: 2rem 0;
    text-align: center;
  }

  .disclaimer {
    line-height: 1rem;
    margin-top: 2rem;
    @include lg {
      line-height: 1.5rem;
    }

    p {
      font-size: $h8;
    }
  }
}
</style>

<i18n>
{
  "lt": {
    "headline": "Jūsų poreikiams pritaikyta saulės elektrinė",
    "title": "Pasinaudokite virtualia skaičiuokle ir sužinokite, kokios galios saulės elektrinė reikalinga Jūsų namams ir kokio sutaupymo galite tikėtis.",
    "instruction": "Pasirinkite savo vidutinį mėnesinį elektros suvartojimą (kWh) arba sąskaitos už elektrą dydį (Eur):",
    "month": "mėn. ",
    "cta": "Gaukite pasiūlymą",
    "disclaimers": [
      "* Paramos dydis vertinamas 323 Eur už 1 kW saulės elektrinės įrengtosios galios.",
      "** Pavyzdžiui, skolinantis 5 000 Eur, kai sudaroma 120 mėn. sutartis, fiksuota metinė palūkanų norma - 4,6%, sutarties sudarymo mokestis – 1,9% (grąžinamas lygiomis dalimis per visą sutarties galiojimo laikotarpį), mėnesio įmoka – 52,85 Eur, bendra sumokama suma – 6342,27 Eur, bendra kredito kainos metinė norma – 5,11%.",
      "*** Vidutiniai taupymai paskaičiuoti remiantis dabartine elektros kaina ({priceKwhCents} ct/kWh)."
    ]
  }
}
</i18n>

<template>
  <div class="Faq">
    <div class="header">
      <div class="title" @click="toggle" @keypress.enter="toggle">
        <h4>{{ faq.question }}</h4>
      </div>
      <Btn class="button-expand" @click="toggle">
        <Icon :name="icon" />
      </Btn>
    </div>
    <transition name="slide-fade">
      <Markdown v-if="isOpen" class="content" :markdown="faq.answer" />
    </transition>
    <div
      v-if="isOpen"
      class="close"
      @click="close"
      @keypress.enter="close">
      {{ $t('close') }}
    </div>
  </div>
</template>

<script>
import { scrollTo } from '../../router/smoothScroll'
const Markdown = () => import(/* webpackChunkName: 'showdown' */ 'views/components/Markdown')

export default {
  components: { Markdown },
  props: {
    faq: { type: Object, required: true },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    icon() {
      return this.isOpen ? 'cross' : 'plus'
    },
  },
  methods: {
    close() {
      this.isOpen = false
      scrollTo(this.$el)
    },
    toggle() {
      this.isOpen = !this.isOpen
    },
  },
}
</script>

<style lang="scss">
$_button-color: #f4f4f4;
$_job-text: $text-color-light;

.Faq {
  border-bottom: 1px solid $border-color-light;

  .video-container {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 35px;
    position: relative;

    iframe {
      border: none;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 3rem;
    padding-top: 3rem;

    .title h4 {
      cursor: pointer;
      font-size: $h35;
    }

    .meta {
      color: $_job-text;
      margin-top: 0.75rem;
      opacity: 0.9;
    }

    .button-expand {
      background: $_button-color;
      border: none;
      border-radius: 50%;
      color: $black;
      float: right;
      height: 2.5rem;
      line-height: 2.5rem;
      min-width: 2.5rem;
      padding: 0;
      width: 2.5rem;

      .Icon {
        display: block;
        transform: translateX(1px);
      }

      &:hover,
      &:focus {
        background: darken($_button-color, 10%);
      }
    }
  }

  .content {
    color: $_job-text;
    font-size: $h6;
    line-height: 2rem;
    max-width: 660px;

    h1, h2, h3 {
      font-size: $h35;
      line-height: 4rem;
    }

    h4 {
      font-size: $h4;
      line-height: 3rem;
    }

    h1, h2, h3, h4, h5 {
      color: $text-color;
      margin: 3rem 0 1rem;
    }

    ul {
      margin-left: 1rem;
    }

    p {
      margin-bottom: 1rem;
    }

    .notice {
      border-top: 1px solid $border-color-light;
      color: $_job-text;
      font-size: $h7;
      margin-top: 2rem;
      padding-top: 1rem;

      .send-to {
        font-size: $h5;
        margin: 1rem 0;
      }
    }
  }

  .close {
    cursor: pointer;
    font-weight: $semibold;
    margin-top: 3rem;
    padding-bottom: 3rem;
    text-align: right;
  }

  &:first-of-type {
    .header {
      padding-top: 0;
    }
  }
}
</style>

<i18n>
{
  "lt": {
    "close": "Uždaryti",
    "notice": "Prašome į savo paraišką įtraukti šį sakinį „Aš sutinku, kad UAB „Nogrid“ tvarkytų mano asmens duomenis, įskaitant ir paraiškos dokumentus, kad užtikrintų sklandų darbuotojų atrankos ir įdarbinimo procesą, pagal 1997 rupjūčio 29 d.asmens duomenų apsaugos aktą (tekstas: Journal of Laws of 2016, pos. 922). Aš taip pat sutinku, kad įmonė tvarkytų mano asmens duomenis galimais atrankos ir įdarbinimo ateityje tikslais.”",
    "perks": "Perks",
    "requirements": "Requirements",
    "send": "Send resume and cover letter to"
  }
}
</i18n>

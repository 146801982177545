<template>
  <div class="Hero">
    <div v-if="src" class="image">
      <Pic :alt="alt" :formats="formats" :src="src" />
    </div>
    <div class="Hero-body">
      <Wrap class="lg">
        <slot />
      </Wrap>
    </div>
    <div v-if="$slots.outside" class="outside">
      <slot name="outside" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formats: {
      type: Array,
      default: () => [],
    },
    src: String,
    alt: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
.Hero {
  align-items: stretch;
  background-color: $black-bis;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 70vh;
  overflow: hidden;
  padding: 4.5rem 0.5rem 0;
  position: relative;

  h1 {
    font-size: $h3;
    text-align: center;
    user-select: none;
    @include md {
      font-size: $h2;
    }
  }

  h2 {
    user-select: none;
  }

  .Row {
    > div {
      margin-bottom: 2rem;
      @include lg {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  > .image {
    img {
      bottom: 0;
      left: 0;
      min-height: 100%;
      min-width: 100%;
      object-fit: cover;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }

  .outside {
    z-index: 3;
  }

  &.lift {
    @include md {
      padding-bottom: 1rem;
      padding-top: 1rem;
    }
    @include lg {
      padding-top: 0;
    }
  }

  &.center {
    text-align: center;
  }
}

.Hero-body {
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  height: 100%;
  position: relative;
  z-index: 2;

  > .Wrap {
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0 auto;
    padding: 0;
    @include xs {
      padding: 0 1.5rem;
    }
  }
}
</style>

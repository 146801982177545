<template>
  <VueSlider
    ref="slider"
    v-bind="$attrs"
    :contained="true"
    :dotSize="1"
    :height="20"
    :lazy="false"
    :railStyle="{
      height: '8px',
    }"
    :tooltip="tooltip"
    :tooltipFormatter="format"
    :tooltipPlacement="tooltipPosition"
    :value="value"
    @change="$emit('update:value', $event)" />
</template>

<script>
import 'vue-slider-component/theme/antd.css'
import VueSlider from 'vue-slider-component'

export default {
  components: { VueSlider },
  props: {
    tooltip: {
      type: String,
      default: 'always',
    },
    value: {
      type: Number,
    },
    format: {
      type: Function,
      default: null,
    },
    tooltipPosition: String,
  },
}
</script>

<style lang="scss">
</style>

<template>
  <div class="PaginationShowMore">
    <div class="pagination">
      <span class="shown-selection">
        <strong>{{ upperLimit }}</strong>/{{ total }}
      </span>

      <Btn
        class="show-more inv"
        :disabled="total === upperLimit"
        @click="$emit('update:limit', getBoundedLimit(increment))">
        {{ $t('more') }}
      </Btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    limit: { type: Number, required: true },
    total: { type: Number, required: true },
    increment: { type: Number, default: 10 },
  },
  computed: {
    upperLimit() {
      return this.getBoundedLimit()
    },
  },
  methods: {
    getBoundedLimit(offset = 0) {
      return Math.min(this.limit + offset, this.total)
    },
  },
}
</script>

<style lang="scss">
.PaginationShowMore {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
  margin-top: 2rem;
  @include md {
    margin-bottom: 4rem;
    margin-top: 4rem;
  }

  .shown-selection {
    color: $black;
    opacity: 0.5;
  }

  .show-more {
    margin-left: 2rem;
    padding: 0.75rem 1.5rem;
  }
}
</style>

<i18n>
{
  "lt": {
    "more": "Rodyti daugiau"
  }
}
</i18n>

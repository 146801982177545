import { getters } from '../utils'
import removeMarkdown from 'remove-markdown'
import { webOrigin } from '../../config'

const metaWithCloneOg = ['description']
const separator = '-'

function formTitle(title, defaultComplement) {
  if (typeof title === 'string') {
    title = {
      inner: title,
      separator,
      complement: defaultComplement,
    }
  }

  return `${title.inner} ${title.separator} ${title.complement}`
}

export default (_title, _description, _image) => {
  const [title, description, image] = (() => {
    if ((typeof _title === 'string' && _title.startsWith('/'))) {
      return [undefined, undefined, _title]
    }

    return [_title, _description, _image]
  })()

  return {
    head: {
      title() {
        return {
          inner: this.metaTitle,
        }
      },
      meta() {
        return [
          {
            id: '_description',
            name: 'description',
            content: removeMarkdown(this.metaDescription),
          },
          {
            id: '_ogdescription',
            property: 'og:description',
            content: removeMarkdown(this.metaDescription),
          },
          {
            id: '_oglocale',
            property: 'og:locale',
            content: this.metaLocale,
          },
          {
            id: '_ogsitename',
            property: 'og:site_name',
            content: this.siteTitle,
          },
          ...(image
            ? [{
              id: '_ogimage',
              content: this.metaImage,
            }]
            : []),
        ]
      },
    },
    computed: {
      ...getters('snippetsValues'),
      siteTitle() {
        return this.$t('site.meta.title', this.snippetsValues)
      },
      defaultDescription() {
        return this.$t('site.meta.description', this.snippetsValues)
      },
      pageTitle() {
        return this.$te('meta.title')
          ? this.$t('meta.title')
          : title
      },
      metaTitle() {
        const metaTitle = this.$_parseMeta(this.pageTitle) || this.siteTitle

        return formTitle(metaTitle, this.siteTitle)
      },
      metaDescription() {
        const localeDescription = this.$te('meta.description')
          ? this.$t('meta.description', this.snippetsValues)
          : description
        const metaDescription = this.$_parseMeta(localeDescription) || this.defaultDescription

        if (metaDescription.length <= 160) {
          return metaDescription
        }

        return `${metaDescription
          .replace(/\n/g, ' ')
          .slice(0, 160)
          .split(' ')
          .slice(0, -1)
          .join(' ')}...`
      },
      metaImage() {
        const imageUrl = this.$_parseMeta(image)

        if (!imageUrl) return

        return imageUrl.startsWith('http')
          ? imageUrl
          : `${webOrigin}${imageUrl.slice(1)}`
      },
      metaLocale() {
        const upperCaseLocale = this.locale === 'en'
          ? 'US'
          : this.locale.toUpperCase()

        return `${this.locale}_${upperCaseLocale}`
      },
    },
    methods: {
      $_parseMeta(metaTag) {
        const metaTagType = typeof metaTag

        if (metaTagType === 'undefined') return

        return metaTagType === 'function'
          ? metaTag.bind(this)(this)
          : metaTag
      },
      $_setMetaKey(name, content, id) {
        if (!content) return
        if (!id) id = `_${name.replace(/\W+/g, '')}`

        if (metaWithCloneOg.includes(name)) {
          this.$_setMetaKey(
            `og:${name}`,
            content,
            id.replace('_', '_og'),
          )
        }
      },
    },
    watch: {
      metaTitle: {
        immediate: true,
        handler(metaTitle) {
          const isTitleSeparated = metaTitle.includes(separator)

          if (!isTitleSeparated) {
            this.title = metaTitle
          }

          this.$_setMetaKey('og:title', metaTitle)
          this.$emit('updateHead')

          this.$nextTick(() => {
            if (isTitleSeparated) {
              window.document.title = metaTitle.trim()
            }
          })
        },
      },
      metaDescription(metaDescription) {
        this.$_setMetaKey('description', removeMarkdown(metaDescription))
        this.$emit('updateHead')
      },
      metaImage(metaImage) {
        this.$_setMetaKey('og:image', metaImage)
        this.$emit('updateHead')
      },
      metaLocale(metaLocale) {
        this.$_setMetaKey('og:locale', metaLocale)
        window.document.documentElement.setAttribute('lang', metaLocale)
        this.$emit('updateHead')
      },
    },
    render: () => '',
  }
}

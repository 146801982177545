var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:_vm.background,attrs:{"id":"Calculator"}},[_c('Wrap',{staticClass:"lg"},[_c('Headline',[_vm._v(_vm._s(_vm.headline || _vm.$t('headline')))]),_c('div',{staticClass:"info"},[_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('title'))+" ")]),_c('small',[_vm._v(" "+_vm._s(_vm.$t('instruction'))+" ")])]),_c('div',{staticClass:"slider-container"},[_c('Slider',{staticClass:"slider",attrs:{"format":_vm.tooltipFormat,"max":_vm.maxValue,"min":_vm.minValue,"processStyle":{
          backgroundColor: '#4dbeed',
        },"tooltipPosition":_vm.tooltipPosition,"tooltipStyle":{
          background: '#4dbeed',
          borderColor: '#4dbeed',
          cursor: 'pointer',
          padding: '0.6rem 0.5rem 0.5rem',
          textAlign: 'center',
          whiteSpace: 'pre',
        },"value":_vm.value},on:{"update:value":function($event){_vm.value=$event}}})],1),_c('SimpleTable',{attrs:{"cols":[8, 4],"rows":_vm.calculated}}),_c('div',{staticClass:"cta-block"},[_c('Btn',{attrs:{"to":{ name: _vm.$route.name, hash: '#FooterContact' }}},[_vm._v(" "+_vm._s(_vm.$t('cta'))+" ")])],1),_c('div',{staticClass:"disclaimer"},_vm._l((_vm.$ta('disclaimers')),function(disclaimer,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(disclaimer.replace('{priceKwhCents}', _vm.priceKwhCents))+" ")])}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
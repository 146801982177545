<template>
  <section id="SectionFaq">
    <Wrap>
      <Headline v-if="doShowHeadline">
        {{ headline || $t('headline') }}
      </Headline>
      <Wrap>
        <div>
          <h3 v-if="title" class="title">
            {{ title }}
          </h3>
          <Faq
            v-for="faq in faqSlice"
            :key="faq.id"
            :faq="faq" />
        </div>

        <div v-if="isUnansweredVisible" class="unanswered">
          <Row>
            <Column :md="8">
              <h4 class="title">
                {{ $t('unanswered.title') }}
              </h4>
              <h5 class="subtitle">
                {{ $t('unanswered.subtitle') }}
              </h5>
            </Column>
            <Column class="button-side" :md="4">
              <Btn class="contact" to="Contact">
                {{ $t('unanswered.contact') }}
              </Btn>
            </Column>
          </Row>
        </div>

        <PaginationShowMore
          v-if="!doShowAllQuestions"
          :limit.sync="limit"
          :total="faqCount" />
      </Wrap>
    </Wrap>
  </section>
</template>

<script>
import Faq from './Faq'
import PaginationShowMore from 'views/components/PaginationShowMore'
import { getters } from 'views/utils'

export default {
  components: { Faq, PaginationShowMore },
  props: {
    doShowAllQuestions: {
      type: Boolean,
      default: false,
    },
    doShowHeadline: {
      type: Boolean,
      default: true,
    },
    faqs: {
      type: Array,
      default: () => [],
    },
    headline: String,
    title: String,
  },
  data() {
    return {
      limit: 3,
      faqIdsHidden: [15],
    }
  },
  computed: {
    faqsFiltered() {
      return this.faqs.filter(faq => !this.faqIdsHidden.includes(faq.id))
    },
    faqCount() {
      return this.faqsFiltered.length
    },
    faqSlice() {
      return this.faqsFiltered.slice(0, this.limit)
    },
    isUnansweredVisible() {
      return (this.limit === this.faqCount || this.limit === undefined)
    },
    ...getters(),
  },
  mounted() {
    if (this.doShowAllQuestions) this.limit = undefined
  },
}
</script>

<style lang="scss">
#SectionFaq {
  background-color: $white;
  @include md {
    padding: 4rem 0;
  }

  .Headline {
    margin-bottom: 4rem;
    @include md {
      margin-bottom: 6rem;
    }
  }

  .Faq {
    &:last-of-type {
      border-bottom: none;
    }
  }

  .unanswered {
    border-top: 1px solid $border-color-light;
    color: $main;

    .title {
      font-size: $h35;
      font-weight: $bold;
      padding: 2rem 0;
    }

    .button-side {
      display: flex;
      justify-content: flex-end;

      .Btn.contact {
        margin: 2rem 0;
        padding: 0.75rem 5rem;
      }
    }
  }

  .PaginationShowMore {
    margin: 2rem 0 1rem;
  }
}

</style>

<i18n>
{
  "lt": {
    "headline": "Dažniausiai užduodami klausimai",
    "unanswered": {
      "title": "Neradote atsakymo į savo klausimą?",
      "subtitle": "Klauskite mūsų eksperto!",
      "contact": "Susisiekti"
    }
  }
}
</i18n>
